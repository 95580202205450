import dataContext from './DataContext'
import chartManager from './ChartManager'
import boardContext from './BoardContext'
import { commonRender } from './rengine/CommonRenderEngine'
import { widgetService } from '../server/widget.service'
import PubSub from '../common/utils/PubSub'
class ChartContext {
    renderWidget = function (containerDom, widgetData, cache) {
        //判断是否已经设置了缓存
        if(!cache || !cache.isCache) {
            //看板没有，将图表的缓存设置载入---widget.widget.data
        }

        // console.log(widgetData)
        const _this = this;
        let { renderId, widgetId, widget, optionFilter, scope, persist, relations, isCockpit } = widgetData
        if (!_.isUndefined(relations)) {
            relations.sourceId = widget.id;
        }
        //widgetId 为渲染domID
        if (_.isUndefined(renderId)) {
            renderId = widgetId
        }
        //2020.11.12原由：table导出需要图表名称----看板的图表名称、图表自己的名称
        if(!!widget) {
            widget.data.name = widgetData.name ? widgetData.name : widget.name;
            // 将renderId和切片器渲染的参数插入chartConfig
            widget.data.config.renderId = renderId;
            widget.data.config.havSlicerRender = widgetData.havSlicerRender === false ? false : true;
        }
        //this.render(containerDom, widget.data, optionFilter, scope, null, persist, relations, isCockpit)
        // PubSub.unsubscribe(widgetId);
        //注册图标 更新通过异步事件通知 
        boardContext.regWidget(renderId, () => {
            // console.log(widget.data)
            if (widget.data.drilled) {
                widgetService.getWidgetDetail({ id: widget.id }).then((data) => {
                    //清除上下钻取带来的数据污染
                    const wdata = JSON.parse(data.widget.data)
                    widget.data.config.filters = wdata.config.filters;
                    widget.data.config.keys = wdata.config.keys;
                    widget.data.config.groups = wdata.config.groups;
                    widget.data.drilled = false;
                    _this.render(containerDom, boardContext.wrapWidgetFilters(widget).data, optionFilter, scope, null, persist, relations, isCockpit, cache)
                })
            } else {
                _this.render(containerDom, boardContext.wrapWidgetFilters(widget).data, optionFilter, scope, null, persist, relations, isCockpit, cache)
            }
        })
        _this.render(containerDom, boardContext.wrapWidgetFilters(widget).data, optionFilter, scope, null, persist, relations, isCockpit, cache)
    }

    render = function (containerDom, widget, optionFilter, scope, reload, persist, relations, isCockpit, cache) {
        // console.log(isCockpit)
        commonRender.loading(containerDom)
        // PubSub.publish('loadBoardWidget',"loading");
        boardContext.addLoadingWidget();
        // return;
        if (isCockpit && widget) {
            //   OurboreEChartRender.prototype.theme = "dark";
            widget.theme = 'dark'
        } else {
            //   OurboreEChartRender.prototype.theme = "theme-fin1"
            widget.theme = "theme-fin1"
        }
        var chart = this.getChart(widget);
     
        return dataContext.getWidgetDataSeries(widget, function (data,datasetDetail) {
            // console.log("dataContextdatasetDetail",datasetDetail)
            boardContext.removeLoadingWidget();
            // PubSub.publish('loadBoardWidget',boardContext.getLoadingWidgetByBoardId());
            if (data.errMsg) {
                commonRender.error(containerDom, data.errMsg)
                return;
            }
            data.containerWidth = containerDom.width();
            try {
                var option = null;
                if (widget.config.chart_type == "custom" && widget.config.option.customConfig && widget.config.option.customConfig.isCustom) {
                    option = chart.parseOption(data, widget.config.option.customConfig.customCode);
                } else if (!widget.config.chart_type || widget.config.chart_type != "custom") {
                    option = chart.parseOption(data);
                    //渲染钱控制主题
                    option.theme = widget.theme;
                    //自定义渲染主题颜色
                    if (widget.config.option.themeColor) {
                        option.backgroundColor = widget.config.option.themeColor.backgroundColor;
                        option.color = widget.config.option.themeColor.color;
                    }
                    if (optionFilter) {
                        optionFilter(option);
                    }
                    if (data.drill) {
                        data.drill.drillDown = function (id, value, render) { //处理下钻逻辑 
                            dataContext.getDrillPath(widget.datasetId, id,datasetDetail).then(function (path) {
                                // console.log(path)
                                var drilled = false
                                var i = 0;
                                _.each(path, function (e, _i) {
                                    if (e.id == id) {
                                        i = _i;
                                    }
                                });
                                var node = path[++i];
                                _.find(widget.config.keys, function (e, _i) {
                                    if (e.id == id) {
                                        e.type = '=';
                                        e.values = [value];
                                        if (!_.find(widget.config.keys, function (e) {
                                            return e.id == node.id;
                                        })) {
                                            widget.config.keys.splice(_i + 1, 0, node);
                                            drilled = true;
                                        } else {
                                            // _.each(widget.config.keys, function(e, _i) {
                                            //   if (e.id != id) {
                                            //     widget.config.keys.splice(_i , 1 );
                                            // }});
                                        }
                                        return true;
                                    }
                                });
                                _.find(widget.config.groups, function (e, _i) {
                                    if (e.id == id) {
                                        e.type = '=';
                                        e.values = [value];//////////////////////////////////////////////////////////todo
                                        if (!_.find(widget.config.groups, function (e) {
                                            return e.id == node.id;
                                        })) {
                                            widget.config.groups.splice(_i + 1, 0, node);
                                            drilled = true;
                                        } else {
                                            // _.each(widget.config.keys, function(e, _i) {
                                            //   if (e.id != id) {
                                            //     widget.config.keys.splice(_i , 1 );
                                            // }});
                                        }
                                        return true;
                                    }
                                });
                                widget.drilled = drilled;
                                dataContext.getWidgetDataSeries(widget, function (data) {
                                    var option = chart.parseOption(data);
                                    if (optionFilter) {
                                        optionFilter(option);
                                    }
                                    render(option, data.drill.config);
                                }, null, cache,datasetDetail);
                            });
                        };
                        data.drill.drillUp = function (id, render) {
                            _.find(widget.config.keys, function (e, _i) {
                                if (e.id == id) {
                                    widget.config.keys[_i - 1].values = [];
                                    widget.config.keys.splice(_i, 1);
                                    return true;
                                }
                            });
                            _.find(widget.config.groups, function (e, _i) {
                                if (e.id == id) {
                                    widget.config.groups[_i - 1].values = [];
                                    widget.config.groups.splice(_i, 1);
                                    return true;
                                }
                            });
                            dataContext.getWidgetDataSeries(widget, function (data) {
                                var option = chart.parseOption(data);
                                if (optionFilter) {
                                    optionFilter(option);
                                }
                                render(option, data.drill.config);
                            }, null, cache,datasetDetail);
                        };
                        data.drill.drillClear = function () {
                            if (widget.config.keys.length > 1) {
                                widget.config.keys.splice(1, widget.config.keys.length - 1);
                            }
                            if (widget.config.groups.length > 1) {
                                widget.config.groups.splice(1, widget.config.groups.length - 1);
                            }
                        }

                    }
                }

            } catch (e) {
                console.log(e)
            } finally {
                try {
                    if (!data) { return }
                    if (widget.config.chart_type == 'chinaMapBmap') {
                        chart.render(containerDom, option, scope, persist, data.drill);
                    } else {
                        chart.render(containerDom, option, scope, persist, data.drill, relations, widget.config, widget.name);
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }, reload, cache);
        
    }

    //调用处已经被注释
    realTimeRender = function (realTimeTicket, widget, optionFilter, scope, widgetWraper, isParamEvent,dataType) {
        if (!realTimeTicket) {
            return;
        }
        var chart = this.getChart(widget);

        if (isParamEvent && widgetWraper) {
            widgetWraper.loading = true;
        }
        var callback = function (data) {
            var option = chart.parseOption(data);
            if (optionFilter) {
                optionFilter(option);
            }
            realTimeTicket(option, data.drill ? data.drill.config : null);
            if (widgetWraper) {
                widgetWraper.loading = false;
            }
        };
        dataContext.getWidgetDataSeries(widget, callback, true);  
    };

    getChart = function (widget) {
        // console.log(widget)
        var chart = chartManager.getChartRender(widget.config.chart_type);
        return chart;
    };

    getChartHeight = function(widget) {
        var chart = chartManager.getChartRender(widget.config.chart_type);
        return chart.height;
    };
}

const chartContext = new ChartContext()

export default chartContext;